import portrait from './images/portrait.png';
import Navbar from './Navbar';
import ReactRotatingText from 'react-rotating-text';
const Home = () => {
    return (
        <div className='h-screen transition-colors'>
            <Navbar />
            <main className='flex flex-col gap-3 lg:gap-0 md:flex-row items-center justify-evenly h-3/4'>
                <h1 className='w-3/6 text-3xl sm:text-4xl md:text-5xl lg:text-6xl transition-all'>
                    Hi, I'm Elya! I am a{' '}
                    <strong className='colored-text'>web developer</strong> and{' '}
                    {
                        <ReactRotatingText
                            items={[
                                'bookworm.',
                                'Radiohead lover.',
                                'juggler.',
                                'rollerskater.',
                                'introvert.',
                                'optimist.',
                                'tea lover.',
                            ]}
                        />
                    }
                </h1>
                <img
                    src={portrait}
                    alt="Elmira's portrait"
                    className='w-4/6 sm:w-3/6 md:w-2/6 lg:w-2/6'></img>
            </main>
        </div>
    );
};
export default Home;
