import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
// import cv from "./documents/ElmiraSaifullina_CV.pdf";
const Navbar = () => {
    const dm = window.localStorage.getItem('dm') === 'true';
    const [lightMode, setLightMode] = useState(dm);

    useEffect(() => {
        const body = document.documentElement;
        if (lightMode) {
            body.className = 'light';
        } else {
            body.className = 'dark';
        }

        window.localStorage.setItem('dm', lightMode);
    }, [lightMode]);

    const handleClick = () => {
        setLightMode(!lightMode);
    };

    return (
        <nav>
            <div className='navlist flex gap-3 lg:gap-5 justify-end p-5 lg:p-10 text-md md:text-xl lg:text-2xl uppercase '>
                <NavLink to='/' name='home' className='navlink'>
                    Home
                </NavLink>
                <NavLink to='/projects' name='projects' className='navlink'>
                    Projects
                </NavLink>
                <NavLink to='/about' name='about' className='navlink'>
                    About
                </NavLink>
                <NavLink to='/contact' name='contact' className='navlink'>
                    Contact
                </NavLink>
                {/* <a href={cv} download="Elmira Saifullina CV">Download CV</a> */}
                <button
                    onClick={handleClick}
                    name='darkColorscheme'
                    title='Dark mode'
                    className='theme-btn dark'>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='h-6 w-6'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke='currentColor'>
                        <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='2'
                            d='M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z'
                        />
                    </svg>
                </button>
                <button
                    className='theme-btn light'
                    onClick={() => handleClick('light')}
                    name='lightColorscheme'
                    title='Light mode'>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='h-6 w-6'
                        viewBox='0 0 20 20'
                        fill='currentColor'>
                        <path
                            fillRule='evenodd'
                            d='M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z'
                            clipRule='evenodd'
                        />
                    </svg>
                </button>
            </div>
        </nav>
    );
};
export default Navbar;
