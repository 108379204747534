import Navbar from './Navbar';
import skills from './skills.js';
const About = () => {
    return (
        <div>
            <Navbar />
            <h3 className='text-4xl pt-10 pb-3 page-header'>About me</h3>
            <p className='w-11/12 lg:w-8/12 mx-auto text-2xl'>
                {`I am a full-stack Node/React software developer passionate about building user-friendly solutions and tackling complex challenges. 
                Transitioning from linguistics to tech out of a love for computers and technology led me to explore and learn JavaScript on my own. 
                I have rapidly excelled as a Node/React developer, embracing the latest technologies and best practices including CI/CD, TDD, 
                and SOLID principles, delivery through small iterations and microservice architectures. Committed to professional growth, 
                I am currently exploring Python, delving into LeetCode challenges, and studying OOP with Uncle Bob’s course to enhance my problem-solving skills 
                and broaden my technical expertise.`}
            </p>
            <p className='text-3xl colored-text my-12'>Skills</p>
            <div className='flex flex-col sm:flex-row sm:gap-1 flex-wrap justify-center lg:justify-evenly'>
                {skills.map((skill, index) => {
                    return (
                        <div key={index}>
                            <i className={`${skill.logo} text-8xl`}>
                                {skill.iconText}
                            </i>
                            <p className='text-sm lg:text-2xl my-2  mx-auto '>
                                {skill.name}
                            </p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
export default About;
