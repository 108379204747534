import Navbar from './Navbar';
import soberPartyPage from './images/sober_party.png';
import memoGamePage from './images/memo_game.png';
import wordleGamePage from './images/wordle_game.png';

const SingleProjectComponent = (props) => {
    return (
        <div className='flex flex-col lg:flex-row justify-evenly items-center'>
            <div className='w-5/6 lg:w-2/6  relative project-image-container'>
                <img
                    src={props.image}
                    alt={props.header}
                    className='project-image'></img>
                <div className='link-above-image w-full'>
                    <a
                        href={props.link}
                        target='_blank'
                        rel='noreferrer'
                        className='text w-full px-2 md:text-3xl md:py-4 md:px-8'>
                        Visit the website
                    </a>
                </div>
            </div>
            <div className='w-5/6 lg:w-2/6'>
                <h4 className='text-3xl py-5 font-semibold'>{props.header}</h4>
                <p className='text-left text-2xl'> {props.text}</p>
                <div className='flex gap-3 mt-1 mb-7'>
                    {/* <p className='colored-text text-2xl font-bold'>Stack:</p> */}
                    <ul className='flex gap-3 lg:gap-5 text-xl lg:text-2xl'>
                        {props.stack.map((technology, index) => (
                            <li key={index} className='colored-text'>
                                {technology}
                            </li>
                        ))}
                    </ul>
                </div>
                <button className='hbtn text-xl lg:text-2xl font-semibold'>
                    <a href={props.link} target='_blank' rel='noreferrer'>
                        Visit the website
                    </a>
                </button>
            </div>
        </div>
    );
};
const Projects = () => {
    const memoText = `I made it just for fun and to learn more about contalletions.
    While creating the game I tried to focus on learning animations and using TDD.`;

    const soberPartyText = `A project I made for myself after struggling to find a website with recipes of mocktails and non-alcoholic drinks. 
    While doing this project I learned how to use Redux.
    `;
    const wordleText = `I like playing wordle, so I've decided to create the game I like in my favourite languages. 
    It's a simple projects with smooth animations and accessible modal from headlessui.`;

    return (
        <div>
            <Navbar />
            <h3 className='text-4xl pt-10 pb-2 page-header'>Projects</h3>
            <h2 className='text-2xl pb-10 w-8/12 mx-auto'>
                {`Something that I create in my free-time for self-learning and
                entertaining purposes. I am not a designer, so don't jusdge my projects'appearance too harshly :)`}
            </h2>

            <div className='flex flex-col gap-10 text-xl mb-5'>
                <SingleProjectComponent
                    image={memoGamePage}
                    header='Constellations memo game'
                    link='https://spacememo.esaifullina.com/'
                    text={memoText}
                    stack={['Next.js', 'Typescript']}
                />
                <SingleProjectComponent
                    image={wordleGamePage}
                    header='Wordle game'
                    link='https://wordle.esaifullina.com/'
                    text={wordleText}
                    stack={['React.js']}
                />
                <SingleProjectComponent
                    image={soberPartyPage}
                    header='Sober Party'
                    link='https://soberparty.esaifullina.com/'
                    text={soberPartyText}
                    stack={['React', 'Node.js', 'Tailwind CSS', 'MongoDB']}
                />
            </div>
        </div>
    );
};
export default Projects;
