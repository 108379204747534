const skills = [
    {
        name: 'JavaScript',
        logo: 'devicon-javascript-plain colored-text',
    },
    {
        name: 'React',
        logo: 'devicon-react-original colored-text',
    },
    {
        name: 'Next.js',
        logo: 'devicon-nextjs-plain colored-text',
    },
    {
        name: 'Typescript',
        logo: 'devicon-typescript-plain colored-text',
    },
    {
        name: 'MongoDB',
        logo: 'devicon-mongodb-plain colored-text',
    },
    {
        name: 'GrapQl',
        logo: 'devicon-graphql-plain colored-text',
    },
];
export default skills;
