import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import Navbar from './Navbar';
const Contact = () => {
const [buttonText, setButtonText] = useState('Send Email');
const [message, setMessage] = useState({
senderName: '',
senderEmail: '',
senderMessage: '',
});
const handleChange = (event) => {
const { name, value } = event.target;
setMessage((prevValue) => {
if (name === 'user_name') {
return {
senderName: value,
senderEmail: prevValue.senderEmail,
senderMessage: prevValue.senderMessage,
};
}
if (name === 'user_email') {
return {
senderName: prevValue.senderName,
senderEmail: value,
senderMessage: prevValue.senderMessage,
};
}
if (name === 'user_message') {
return {
senderName: prevValue.senderName,
senderEmail: prevValue.senderEmail,
senderMessage: value,
};
}
});
};
const handleClick = (e) => {
e.preventDefault();
emailjs
.send(
'service_kbjdvl4',
'template_fzwtbr3',
{
from_name: message.senderName,
message: message.senderMessage,
from_email: message.senderEmail,
},
'user_qM5g1zhJlzTpO2v22X8WF'
)
.then(
(result) => {
console.log(result.text);
},
(error) => {
console.log(error.text);
}
);
setButtonText('The message was sent');
};
return (
<div>
<Navbar />
<h3 className='text-4xl py-10 page-header'>Contact</h3>
<p className='text-3xl'>Let's get in touch</p>
<p className='w-11/12 md:w-9/12 lg:w-7/12 mx-auto text-2xl py-7'>
Drop a line to say g’day, ask for my CV or see if we can build something amazing
together. I’d love to hear from you! Fill in your info in the form below to talk
about projects we can build together or just say hi!
</p>
<form
className='flex flex-col gap-5 w-4/6 md:w-1/3 mx-auto'
onSubmit={handleClick}>
<input
required
placeholder='Your name'
type='text'
name='user_name'
onChange={handleChange}
className='feedback-input required text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'></input>
<input
type='email'
name='user_email'
id='email'
className='feedback-input required text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 '
placeholder='Your email'
onChange={handleChange}
/>
<textarea
id='message'
rows='4'
name='user_message'
className='feedback-input block required p-2 w-full text-sm rounded-lg border  focus:ring-blue-500 focus:border-blue-500'
placeholder='Leave your message here...'
onChange={handleChange}></textarea>
<button type='submit' className='hbtn'>
{buttonText}
</button>
</form>
<p className='text-xl md:text-2xl py-5'>
{' '}
Feeling social? Find me on these online spaces
</p>
<div className='flex gap-5 justify-center text-3xl colored-text mb-5'>
<a
href='https://www.linkedin.com/in/elmira-saifullina/'
rel='noreferrer'
target='_blank'>
<i className='bi bi-linkedin'></i>
</a>
<a href='https://github.com/Elyamira' target='_blank' rel='noreferrer'>
<i className='bi bi-github'></i>
</a>
<a href='https://www.instagram.com/ely_mira/' target='_blank' rel='noreferrer'>
<i className='bi bi-instagram'></i>
</a>
</div>
</div>
);
};
export default Contact;
